<template>
    <div>
        <van-popup v-model="showActivateCopy" position="bottom" :style="{backgroundColor: 'transparent'}" @close="close" safe-area-inset-bottom>
            <div class="activate">
                <div class="activate-title">是否确认激活？</div>

                <div v-if="0" class="activate-content col-between-center">
                    <div class="activate-text">激活后今天即可开始生效使用权益</div>
                </div>
                <div v-else class="venue-content">
                    <div class="activate-tips">激活后今天即可开始生效使用权益，激活前<span>请选择权益卡常去门店</span>，这有助于我们未来上架该门店的优惠活动</div>
                    <div class="select-venue row-start-center">
                        <p>门店</p>
                        <div class="row-between-center" @click="showCV">
                            <span :class="{active: venueIdCopy}">{{ venueName || '请选择门店' }}</span>
                            <span v-if="venueIdCopy" class="icon">更换</span>
                        </div>
                    </div>
                </div>

                <div class="tips-text">同门店卡同时期仅可生效1张，不同门店卡可另外激活生效使用</div>
                <div class="activate-btn row-between-center">
                    <div @click="showActivate = false">我再想想</div>
                    <div :class="{gray: !venueIdCopy}" @click="activate()">确认激活使用</div>
                </div>
            </div>
        </van-popup>

        <!--激活确认弹窗-->
        <van-popup v-model="showActivate" :style="{backgroundColor: 'transparent'}" @close="close">
            <div class="popup-box-activate">
                <div class="popup-title">是否立即激活？</div>
                <div v-if="cardType === 0" class="popup-content">确认激活则直接生效当前权益卡的权益包，且无法再赠送给亲友，激活后今天开始生效，确认是否马上激活？</div>
                <div v-if="cardType === 1" class="popup-content">确认激活则直接生效当前权益卡权益包，激活后今天开始生效，是否马上激活？</div>
                <div v-if="cardType === 3" class="popup-content">确认激活则直接生效当前权益卡下的权益，激活后从今天开始生效。</div>
                <div v-if="cardType === 2" class="popup-content"> 用户若同时购入多张超鹿包月私教，
                    <span>仅可生效一张，</span>激活前请确认月卡有效期内可预约使用的门店及教练
                    <div style="margin-top: 4vw">确认激活则直接生效当前权益卡下的权益，激活后从今天开始生效。</div>
                </div>
                <div class="popup-btn-copy row-start-start">
                    <div @click="showActivate = false">我再想想</div>
                    <div @click="activate()">确认激活</div>
                </div>
            </div>
        </van-popup>

        <!--激活成功弹窗-->
        <van-popup v-model="showActivateSuccess" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png" alt="">
                    <div>激活生效成功!</div>
                </div>
                <div class="pay-share-btn row-between-center">
                    <div @click="jumpMyOrder" class="flex-auto">继续约课</div>
                </div>
            </div>
            <img @click="showActivateSuccess = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="">
        </van-popup>

        <!-- 激活失败弹窗 -->
        <van-popup v-model="showActivateFail" :style="{backgroundColor: 'transparent'}" @close="close">
            <div class="popup-box-activate">
                <div class="popup-title">无法激活</div>
                <div class="popup-content">激活则直接生效当前权益卡权益包</div>
                <div class="popup-btn row-start-start">
                    <div @click="showActivateFail = false">我知道了</div>
                </div>
            </div>
        </van-popup>

        <cl-city-venue2 :data="storeList" :popShow.sync="storeShow" @popfun="choseStorePop" type='venue'
                        :chose-item="choseItem" :single="true" btn-text="确认"></cl-city-venue2>
    </div>

</template>
<script>
import userMixin from "@/mixin/userMixin";
import clCityVenue2 from "@/components/cl-city-venue/index3";
import wx from "weixin-js-sdk";
import {closeWindow} from "@/lib/appMethod";
import {getParam} from "@/lib/utils";
export default {
    name: "activate",
    components: {clCityVenue2},
    mixins: [userMixin],
    props: {
        show: {
            type: Boolean,
            default: true
        },
        userEquityCardId: {
            type: [String, Number],
            default: ''
        },
        cardType: {
            type: Number,
            default: 0
        },
        userInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        show(v) {
            this.showActivate = v
        },
    },
    data() {
        return {
            showActivate: false,
            showActivateFail: false,
            showActivateCopy: false,
            storeShow: false, // 场馆选择
            choseItem: {}, //原生的选择对象
            storeList: [], //原生的选择对象
            venueIdCopy: null, //原生的选择对象
            venueName: '',
            showActivateSuccess: false,
        }
    },
    async created() {
        // await this.$getUserId();

        console.log(this.$route.query.fromOrder)
    },
    methods: {
        activate() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/user/equityCard/open`, {
                ...this.userInfo,
                userEquityCardId: this.userEquityCardId
            }).then((res) => {
                this.$toast.clear()
                this.close()
                if(this.$route.query.fromOrder === '1'){
                    this.showActivateSuccess = true
                }
                this.$emit('confirm', true)
            }).catch(() => {
                // this.$toast.clear()
            })
        },
        close() {
            this.$emit('update:show', false)
            this.showActivate = false
        },
        showCV() {
            // this.$refs.refCityVenue.popShow = true;
            this.storeShow = true
        },
        // 获取城市门店
        getCityAndVenue() {
            this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then(res => {
                if (res.code == 1) {
                    this.cityAndVenue = []
                    this.cityList = res.data[0].children
                    this.initCityList = JSON.parse(JSON.stringify(res.data[0].children))
                    this.setCityAndStore()
                }
            })
        },
        setCityAndStore() {
            if (this.cityId) {
                let _province = this.cityList.find(x => {
                    return x.children.some(y => y.id === this.cityId)
                })
                this.choseItem.cityId = this.cityId
                this.choseItem.provinceId = _province.id
                this.cityList.forEach((value, index) => {
                    let _result = value.children.find(y => y.id === this.cityId)
                    if (_result) {
                        this.storeList = _result.children
                    }
                })
            }
        },
        //门店弹出窗口返回的数据
        choseStorePop(val, param, choseName) {
            this.storeShow = false
            this.storeTotal = choseName || this.storeTotal
            if (param) {
                let {venueId, venueName, areaId} = param
                this.choseItem.venueId = venueId || []
                this.choseItem.areaId = areaId
                this.venueIdCopy = venueId[0]
                this.venueName = venueName
                console.log(this.venueIdCopy, this.venueName)
            }
        },
        // 跳转下单页面
        jumpMyOrder() {
            console.log('执行了返回')
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateBack();
            } else {
                closeWindow()
            }
        },
    }
}
</script>

<style scoped lang="less">
.activate{
    background-color: white;
    border-radius: 16px 16px 0 0;
    .activate-title{
        padding: 48px 0 40px;
        font-weight: bold;
        line-height: 32px;
        color: #242831;
        font-size: 32px;
        text-align: center;
    }
    .venue-content{
        padding: 24px 32px 32px;
        height: 404px;
        box-sizing: border-box;
        .activate-tips{
            line-height: 48px;
            color: #242831;
            font-size: 26px;
            span{
                color: #267DFF;
            }
        }
        .select-venue{
            margin-top: 94px;
            p{
                width: 120px;
                color: #242831;
                font-size: 28px;
            }
            div{
                flex-grow: 1;
                padding: 18px 0;
                line-height: 42px;
                font-size: 30px;
                border-bottom: 2px solid #DDDDDD;
                color: #6C727A;
                span.active{
                    color: #242831;
                    font-weight: bold;
                }
                .icon{
                    line-height: 24px;
                    font-size: 24px;
                    background: url("https://img.chaolu.com.cn/ACT/icon/change_icon.png") no-repeat left center;
                    background-size: 24px 24px;
                    padding-left: 28px;
                    color: #242831;
                }
            }
        }
    }
    .activate-content{
        width: 750px;
        height: 404px;
        padding: 128px 32px 32px;
        box-sizing: border-box;
        .activate-text{
            font-size: 32px;
            font-weight: bold;
            color: #242831;
            line-height: 32px;
            text-align: center;
        }
    }
    .tips-text{
        text-align: center;
        line-height: 24px;
        color: #6C727A;
        font-size: 24px;
        padding: 32px;
    }
    .activate-btn{
        border-top: 1px solid #EAEAEA;
        padding: 28px 32px;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 328px;
            height: 88px;
            border-radius: 8px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;
            &:last-child{
                border-color: #FFDE00;
                background: #FFDE00;
                font-weight: bold;
                color: #242831;
            }
            &.gray{
                border-color: #EEEEEE;
                color: #9AA1A9;
                background: #EEEEEE;
            }
        }
    }
}
// 错误弹窗
.popup-box-activate{
    width: 560px;
    background: #FFFFFF;
    border-radius: 12px;
    .popup-title{
        padding: 64px 0 32px;
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        color: #242831;
    }
    .popup-content{
        padding: 0 60px;
        text-align: center;
        font-size: 24px;
        line-height: 44px;
        color: #3C454E;
        span{
            color: #FF6E00;
        }
    }
    .popup-btn{
        border-top: 1px solid #EAEAEA;
        margin-top: 48px;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 112px;
            font-size: 32px;
            color: #FF6E00;
        }
    }
    .popup-btn-copy{
        border-top: 1px solid #EAEAEA;
        margin-top: 48px;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 108px;
            width: 50%;
            font-size: 32px;
            color: #9AA1A9;
            &:last-child{
                border-left: 1px solid #EAEAEA;
                color: #FF6E00;
            }
        }
    }
}
// 激活成功弹窗
.pay-box {
    width: 590px;
    box-sizing: border-box;
    margin: 0 auto;
    background: white url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-success-bg.png") no-repeat center top;
    background-size: 590px 554px;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 40px;
    .pay-box-title {
        img {
            width: 128px;
            height: 128px;
            display: block;
            margin: 0 auto;
            padding: 64px 0 48px;
        }
        div {
            font-weight: bold;
            color: #242831;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
    }
    .pay-share-btn {
        div{
            width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            border-radius: 8px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            &:last-child{
                font-weight: bold;
                background: #FFDE00;
                border-color: #FFDE00;
            }
        }
        width: 510px;
        height: 96px;
        margin: 128px auto 0;
        color: #242831;
    }
}
.result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
}
</style>
